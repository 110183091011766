<template>
  <list-container-widget
    id="events"
    :app-id="app.id"
    :view-more-location="getLocation('events')"
    :size="addOnSize"
  >
    <template #actions>
      <!-- Panel actions -->
      <widget-actions
        item-type="events"
        :show-add="canAdd"
        :show-list="canList"
        :show-filter=false
        @updateSearch="searchItems"
      />
    </template>

    <!-- If the loggedUser does not have permissions -->
    <div v-if="!canList" class="horizontal-placeholder">
      <p class="text-primary">
        {{ $t("no-permisions.message") }}
      </p>
    </div>

    <events-horizontal-list
      v-if="canList"
      :can-view="canView"
      :force-events="forceEvents"
      :key="reload"
      :reload="reload"
      :value="valueSearch"
      @events-updated="setAddOnSize($event.length)"
    />

    <!-- Create event -->
    <b-modal
      id="modal-create-event"
      :title="$t('events.actions.create-event')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :ok-disabled="!isNotDisabled"
      @ok="addEvent"
    >
      <b-form @change="$emit('update-event', eventInput)">
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input id="name" v-model="eventInput.name" type="text" />
        </b-form-group>
        <b-form-group>
          <label for="type">{{ $t("events.actions.type-options") }}:</label>
          <v-select
            id="type"
            v-model="selectedOption"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            @input="modality"
          />
        </b-form-group>
        <!-- Accessibility -->
        <b-form-group>
          <label for="type"
            >{{ $t("events.actions.accessibility.title") }}:</label
          >
          <v-select
            id="type"
            v-model="accessibilityOption"
            :clearable="false"
            :searchable="false"
            :options="accessibilityOptions"
            @input="accessibility"
          />
        </b-form-group>
        <!-- Organizer -->
        <b-form-group>
          <label for="type">{{ $t("events.actions.organizer.title") }}:</label>
          <b-form-checkbox switch v-model="showCreated" />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="eventInput.description" />
        </b-form-group>
        <b-form-group
          :label="
            `${$t('calendar.meeting-form.start-date.label')}*  (${$t(
              'events.event-details.local-time'
            )})`
          "
        >
          <VueCtkDateTimePicker
            v-model="eventInput.start"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
          />
        </b-form-group>
        <b-form-group
          :label="
            `${$t('calendar.meeting-form.end-date.label')}*  (${$t(
              'events.event-details.local-time'
            )})`
          "
        >
          <VueCtkDateTimePicker
            v-model="eventInput.end"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
            :min-date="eventInput.start"
          />
        </b-form-group>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <b-media
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0;"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="eventInput.thumbnailURL"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import EventsHorizontalList from "@/views/apps/events/components/EventsHorizontalList.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import EventCard from "@/views/apps/events/components/EventCard.vue";
import { EVENTS_ACTIONS } from "@/views/apps/events/constants/events-store-constants";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { quillEditor } from "vue-quill-editor";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import vSelect from "vue-select";
import { checkPermissions } from "@/@core/utils/roles-utils";
import EventsPlaceholder from "@/assets/images/placeholders/light/events.svg";
import FileTypes from "@/@core/constants/FileTypes";

export default {
  name: "EventsHorizontalListWidget",
  components: {
    EventCard,
    ListContainerWidget,
    EventsHorizontalList,
    WidgetActions,
    quillEditor,
    vSelect,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  data() {
    return {
      eventInput: {},
      imageSrc: null,
      updateEvent: null,
      selectedOption: "",
      forceEvents: false,
      reload: 0,
      accessibilityOption: "",
      showCreated: false,
      valueSearch: "",
      events: [],
    };
  },
  computed: {
    isNotDisabled() {
      return (
        this.eventInput.end && this.eventInput.start && this.eventInput.name
      );
    },
    FileTypes() {
      return FileTypes;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    typeOptions() {
      return [
        this.$t("events.event-details.online-event"),
        this.$t("events.event-details.on-site-event"),
        this.$t("events.event-details.hybrid"),
      ];
    },
    accessibilityOptions() {
      return [
        this.$t("events.actions.accessibility.invitation"),
        this.$t("events.actions.accessibility.with-request"),
        this.$t("events.actions.accessibility.without-request"),
      ];
    },
    config() {
      return {
        wrap: true,
        enableTime: true,
        dateFormat: "Z",
        altInput: true,
        altFormat: "Y-m-d h:i K",
        minuteIncrement: 1,
        disableMobile: "true",
      };
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "event",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    canList() {
      return checkPermissions(
        "index",
        "event",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "event",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    eventPlaceholder() {
      return EventsPlaceholder;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchEvent();
    this.selectedOption = this.typeOptions[0];
    this.accessibilityOption = this.accessibilityOptions[0];
    this.isLoading = false;
  },
  methods: {
    searchItems(value){
      this.valueSearch = value;
    },
    async handleEditEvent(item) {
      this.updateEvent = item;
    },
    async addEvent() {
      try {
        await this.$store.dispatch(EVENTS_ACTIONS.createEvent, {
          locale: this.$store.state.locale.currentLocale,
          collectiveKey: this.$store.getters.currentCollective.key,
          event: this.eventInput,
          withShared: true,
          modality: this.eventInput.modality,
          accessibility: this.eventInput.accessibility,
          showCreated: this.showCreated,
          eventType: 27452,
          thumbnailURL: this.eventInput.thumbnailURL,
        });
        this.eventInput = {};
        this.notifySuccess(this.$t("events.actions.success-creation"));
        this.forceEvents = true;
      } catch (error) {
        this.notifyError(this.$t("events.actions.error-creation"));
        this.eventInput = {};
      }
    },
    async fetchEvent() {
      const response = await this.$store.dispatch(
        EVENTS_ACTIONS.fetchEventList,
        {
          visibleOncommunityKey: this.$store.getters.currentCollective.key,
          excludeStreaming: true,
          count: 100,
        }
      );
  
      return response?.data?.data;
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeOptions[1]:
          this.eventInput.modality = 0;
          return;
        case this.typeOptions[2]:
          this.eventInput.modality = 2;
          return;
        case this.typeOptions[0]:
          this.eventInput.modality = 1;
          return;
      }
    },
    accessibility() {
      switch (this.accessibilityOption) {
        case this.accessibilityOptions[1]:
          this.eventInput.accessibility = 1;
          return;
        case this.accessibilityOptions[2]:
          this.eventInput.accessibility = 0;
          return;
        case this.accessibilityOptions[0]:
          this.eventInput.accessibility = 2;
          return;
      }
    },
  },
};
</script>
